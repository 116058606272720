import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
//MODELS
import { MeetEvent, WeekDay, Meeting } from '../models/goldmine.model';
//COMPONENTS AND SERVICES
import { BackendConnectionService } from './backend.connection.service';
import { UserService } from './user.service';
import { UtilitiesService } from './utilities.service';
import { LoaderDialogComponent } from '@app/modules/loader-dialog/loader-dialog.component';
import { MatDialog } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class GoldmineService {

    public events: MeetEvent[];
    public meetingsSubject: Subject<any> = new Subject;
    public meetingsLoaded: boolean = false;

    //subject called from meeting details to let the rest of the app know a meeting has ben changed.
    public updateMeetingSubject: Subject<any> = new Subject;

    public createContactSubject: Subject<any> = new Subject;

    public eventExplodeIndex: number = 0;   //index for events array
    public weekdayExplodeIndex: number = 0; //index for weekday array inside events object
    public meetingIndex: number = 0;        //index for meeting array inside weekday object

    constructor(
        private userService: UserService,
        private backendService: BackendConnectionService,
        private router: Router,
        private utilities: UtilitiesService,
        private dialog: MatDialog
    ) {
        console.log("goldmine service created");
        //get indexes from local storage if they exist
        if (localStorage.getItem('weekdayExplodeIndex') &&
            localStorage.getItem('eventExplodeIndex')) {
            this.weekdayExplodeIndex = +localStorage.getItem('weekdayExplodeIndex');
            this.eventExplodeIndex = +localStorage.getItem('eventExplodeIndex');
            this.meetingIndex = +localStorage.getItem('meetingIndex');
        } else {
            //send the user back to make a selection
            this.router.navigate(['splash/planner']);
        }

        this.userService.userServiceSubjet.subscribe(
            () => {
                if (this.userService.isLoggedIn) {
                    this._initMeetings();
                }
            }
        );
    }

    public _initMeetings() {
        //list all the meetings from goldMine for the user
        this.backendService.getRequest<MeetEvent[]>(this.userService.token, '/getMeetings').
            subscribe(
                (res) => {
                    this.events = res;
                    this.events.forEach((event, index) => {
                        let meetingCount = 0;
                        //helpers for event that lives in memory
                        event.id = this.utilities.randString(7);
                        event.isCollapsed = true;
                        if (index == this.eventExplodeIndex) {
                            event.isCollapsed = false;
                        }
                        //convert dates to type Date()
                        event.convertOnDate = this.utilities.convertDate(event.ondate);
                        event.convertEndDate = this.utilities.convertDate(event.enddate);

                        event.signupMeetings.forEach((si) => {
                            //Format date and time(s) into a more readable format.
                            //Set it to a new title property on each signup meeting.
                            let siDate = new Date(si.dateTime+ "Z");
                            si.title = this.utilities.dateToStringDay(siDate) + ": "
                                + siDate.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric'}) + " - ";
                            siDate.setMinutes( siDate.getMinutes() + +si.duration );
                            si.title += siDate.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric'});
                            //collapse property for template.
                            si.collapse = true;
                            si.gm_identifier_not_found = +si.gm_identifier_not_found;
                            si.searchResults = [];
                            si.companyName = undefined;
                        });

                        event.weekDays.forEach(item => {

                            item.tradeShowCount = 0;
                            item.appCount = 0;
                            //also convert the dates in the  weekDays array
                            item.convertDate = this.utilities.convertDate(item.date);

                            item.meetings.forEach(meeting => {
                                meetingCount ++;
                                meeting.isTradeShow = false;
                                meeting.isMultiCompany = false;
                                switch (meeting.actvcode) {
                                    case 'BTH':
                                        meeting.formalType = "Booth Show";
                                        meeting.isTradeShow = true;
                                        item.tradeShowCount++;
                                        meeting.isMultiCompany = true;
                                        if(meeting.linkedMeeting){
                                            meeting.linkedMeeting.child_mid = +meeting.linkedMeeting.child_mid
                                        }
                                        break;
                                    case 'WEB':
                                        if(meeting.rectype == 'A'){
                                            item.appCount++;
                                            break;
                                        }
                                        meeting.formalType = "Multi-company Webinar";
                                        meeting.isTradeShow = true;
                                        item.tradeShowCount++;
                                        meeting.isMultiCompany = true;
                                        break;
                                    case 'DIS':
                                        meeting.formalType = "Distrubutor Show";
                                        meeting.isTradeShow = true;
                                        meeting.isMultiCompany = true;
                                        item.tradeShowCount++;
                                        break;
                                    case 'SPE':
                                        meeting.formalType = "Speed Show";
                                        meeting.isTradeShow = true;
                                        meeting.isMultiCompany = true;
                                        item.tradeShowCount++;
                                        break;
                                    default:
                                        item.appCount++;
                                        meeting.formalType = "NONE";
                                }
                                meeting.attendeeCount = Number(meeting.attendeeCount);
                                let date = this.utilities.convertDateUtc(meeting.ondate, meeting.ontime);
                                meeting.convertDateTime = date.toISOString().slice(0, 16);

                            });
                        });
                        event.meetingCount = meetingCount;
                    });
                    console.log(this.events);
                },
                (err) => console.log(err),
                () => {
                    this.meetingsLoaded = true;
                    this.meetingsSubject.next();
                    /**
                     * If meeting index is 0, we have to inform the Splash Component that we are
                     * on index 0. If it's not zero the carousel mechanics take care of it.
                     */
                    if (this.meetingIndex === 0 && this.router.url !== '/splash/planner') {
                        console.log("updating meeting");
                        this.updateMeeting();
                    }
                }
            );
    }

    /**
     * CALLED FROM MEETING DETAILS WHENEVER THE CAROUSEL IF MOVED
     * THIS LETS THE REST OF THE APP KNOW WHEN A MEETING HAS BEEN CHANGED
     * PARTICULLARY THE SPLASH COMPONENT
     * @param index 
     */
    public updateMeeting(index: number = 0) {
        this.meetingIndex = index;
        this.updateMeetingSubject.next(this.events[this.eventExplodeIndex]
            .weekDays[this.weekdayExplodeIndex].meetings[this.meetingIndex]);
    }

    public deleteMeeting() {
        this.events[this.eventExplodeIndex]
            .weekDays[this.weekdayExplodeIndex].meetings.splice(this.meetingIndex, 1);
    }

    public get event() {
        if(this.events[this.eventExplodeIndex])
            return this.events[this.eventExplodeIndex];
        if(this.events[0])
            return this.events[0];
        return new MeetEvent;
    }

    public get weekday() {
        if(this.event.weekDays[this.weekdayExplodeIndex])
            return this.event.weekDays[this.weekdayExplodeIndex];
        this.router.navigate(['/splash/planner']);
        return new WeekDay;
    }

    public get meeting() {
        if(this.weekday.meetings[this.meetingIndex])
            return this.weekday.meetings[this.meetingIndex];
        this.router.navigate(['/splash/planner']);
        return new Meeting;
    }

    public createContact(data: any): void {
        let loaderDialogRef = this.dialog.open(LoaderDialogComponent, { panelClass: 'smaller-container-width' });
        this.backendService.postRequest(
            this.userService.token, '/createContact', data).
            subscribe(
                (res: any) => {
                    this.createContactSubject.next(res);
                },
                (err) => {
                    console.log(err);
                    alert("Warning, something went wrong while creating contact!\nPlease check your connection!\n"
                        + "Error:" + err);
                },
                () => { loaderDialogRef.close(); }
            );
    }

    /**
     * Essential the first process to navigating to a specif MID.
     */
    public setExplodesFromMid(mid: number){
        for(let x = 0; x < this.events.length; x ++){
            let event = this.events[x];
            for(let y = 0; y < event.weekDays.length; y++){
                let weekDay = event.weekDays[y];
                for(let z = 0; z < weekDay.meetings.length; z++){
                    let meeting = weekDay.meetings[z];
                    if(mid == meeting.mid){
                        console.log("meeting found");
                        this.eventExplodeIndex = x;
                        this.weekdayExplodeIndex = y;
                        this.updateMeeting(z);
                        console.log(x, y, z);
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
