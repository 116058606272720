import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from '@app/app.component';
import { AdminComponent } from '@app/modules/auth/admin/admin.component';
import { SplashModule, ValuesPipe } from '@app/modules/splash.routes.module';
import { RouteGuard } from '@app/route.guard';
import { UserService } from './core/services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { NotfoundComponent } from './modules/notfound/notfound.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { AuthModule } from './modules/auth/auth.module';
import { Config } from './core/config';
import { GoldmineService } from './core/services/goldmine.service';
import {
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatTooltipModule,
    MatCheckboxModule
} from '@angular/material';
import { PhoneSelectComponent } from './modules/meetingdetails/phone-select/phone-select.component';
import { SelectorDialogComponent } from './modules/selector-dialog/selector-dialog.component';
import { AddContactComponent } from './modules/meetingdetails/attendees/add-contact/add-contact.component';
import { EmailDialogComponent } from './modules/meetingdetails/post-email/email-dialog/email-dialog.component';
import { GMAppUpdateComponent } from './modules/meetingdetails/gm-update-dialogs/gm-app-update.component';
import { LoaderDialogComponent } from './modules/loader-dialog/loader-dialog.component';
import { GMContactUpdateComponent } from './modules/meetingdetails/gm-update-dialogs/gm-contact-update.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { FinalSelectorDialogComponent } from './modules/selector-dialog/final-selector-dialog.component';
import { MeetingLinkComponentComponent } from './modules/selector-dialog/meeting-link-component/meeting-link-component.component';
import { AppointmentsignupComponent } from './modules/appointmentsignup.component';
import { AppointmentsignupDialogComponent } from './modules/appointmentsignup-dialog/appointmentsignup-dialog.component';
import { CollapseModule } from './core/collapse';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'admin',
        canActivate: [RouteGuard], //set to functin isAdmin
        component: AdminComponent
    },
    {
        path: "signup/:name",
        component: AppointmentsignupComponent
    },
    {
        path: '',
        redirectTo: '/splash/planner',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/splash/planner'
    }
];

//Configure the application to use vertical swiping
//https://github.com/hammerjs/hammer.js/issues/1014
declare var Hammer: any;
export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'swipe': { direction: 6 },
    }
    buildHammer(element: HTMLElement) {
        let mc = new Hammer(element, {
            touchAction: "pan-y",
        });
        return mc;
    }
}


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1
};



@NgModule({
    declarations: [
        ValuesPipe,
        AppComponent,
        AdminComponent,
        PhoneSelectComponent,
        SelectorDialogComponent,
        AddContactComponent,
        EmailDialogComponent,
        GMAppUpdateComponent,
        GMContactUpdateComponent,
        LoaderDialogComponent,
        FinalSelectorDialogComponent,
        MeetingLinkComponentComponent,
        AppointmentsignupComponent,
        AppointmentsignupDialogComponent
    ],
    imports: [
        BrowserModule,
        SwiperModule,
        HttpClientModule,
        FormsModule,
        SplashModule, //Router
        AuthModule,
        MatDialogModule,
        MatExpansionModule,
        MatTabsModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        MatRadioModule,
        MatTooltipModule,
        MatCheckboxModule,
        CollapseModule,
        RouterModule.forRoot(routes)
    ],
    providers: [
        RouteGuard,
        Config,
        UserService,
        GoldmineService,
        CookieService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        },
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        }
    ],
    entryComponents: [
        PhoneSelectComponent,
        SelectorDialogComponent,
        AddContactComponent,
        EmailDialogComponent,
        GMAppUpdateComponent,
        GMContactUpdateComponent,
        LoaderDialogComponent,
        FinalSelectorDialogComponent,
        MeetingLinkComponentComponent,
        AppointmentsignupDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
