import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class LoginComponent implements OnInit {
  
  public innerHeight: number;
  
  constructor(
    public userService: UserService,
    private router: Router
  ) {}
        
  ngOnInit() {
    this.userService._init();
    this.innerHeight = window.innerHeight;
    //if logged in already, send user back to the planner
    if(this.userService.isLoggedIn){
      this.router.navigate(['splash/planner']);
    }
  }

  onResize(event){
    this.innerHeight = event.target.innerHeight; // window width
  }
}
