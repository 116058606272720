import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
//MODELS
import { User } from '../models/user.model';
//COMPONENTS AND SERVICES
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class UserConnection {
                  
  constructor(
    private http: HttpClient,
    private config: Config
    ) {}

  //post method for logging into lDap
  login(data: NgForm): Observable<User> { 
    let input = new FormData();
    input.append('username', data.value.username);
    input.append('password', data.value.password);
    return this.http.post(`${this.config.BASE_SERVER_URL}/ldapLogin`, input)
      .pipe(
        map((res) => {
          return <User>res;
        }),
        catchError(this.handleError)
      );
  }

  //post method
  refreshUser(cookie: string): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders()
        .set('Authorization' , cookie)
    };
    return this.http.post(`${this.config.BASE_SERVER_URL}/refreshUser`, null, httpOptions)
      .pipe(
        map((res) => {
          return <User>res;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      console.error('An error occurred:', error.error.message);
    } else {
      //debug, log entire erro
      console.log(error);
      // A Server error occured
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
      }
    //all the meet master messages will be in this format
    return throwError(error.error.message || error.status);
  }
}
