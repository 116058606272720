import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Attendee, LinkedAttendee } from '../models/attendees.model';
import { BackendConnectionService } from './backend.connection.service';
import { UserService } from './user.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class AttendeesService {
  
  public attendeesLoaded: boolean;

  private attendees: Attendee[] = [];
  public linkedAttendees: LinkedAttendee[] = [];
  public attendeesSubject: Subject<any> = new Subject;

  private lastMeetingIndex: number;

  constructor(
    private backendService: BackendConnectionService,
    private userService: UserService,
    private utilities: UtilitiesService
  ) {
    console.log("Attendee service created");
  }

   /**
   * GETS DATA FROM THE ATTENDEES TABLE AND TRANSCRIBES IT FOR USE IN THE APP
   * ATTENDEES GET LOADED WHENEVER IS CONVENIENT
   */
  public getAttendees(meetingId: number): void{
    if(meetingId !== this.lastMeetingIndex){
      this.backendService.getRequest<Attendee[]>(this.userService.token,
        `/getAttendees?mid=${meetingId}&stf=1`)
     .subscribe(
       (res: Attendee[]) => {
         if(res){
           this.attendees.length = 0;
           res.forEach(person => {
             person.is_confirmed = Number(person.is_confirmed);
             person.is_meeting_contact = Number(person.is_meeting_contact);
             person.mark_email_change = Number(person.mark_email_change);
             person.aid = Number(person.aid);
             person.service_team_flyer = Number(person.service_team_flyer);
             person.sent_pre_email = Number(person.sent_pre_email);
             person.sent_post_email = Number(person.sent_post_email);
             person.color = this.utilities.color;
             this.attendees.push(new Attendee(person));
           });
         } else {
           this.attendees.length = 0;
           console.log("No attendees found");
         }
       },
       (err) => { 
         console.log(err);
         alert("Warning, attendees were not loaded properly!\nPlease check your connection!\n" 
         + "Error:" +  err);
        },
       () => {
        this.lastMeetingIndex = meetingId;
        this.attendeesLoaded = true;
        this.attendeesSubject.next(this.attendees);
       }
     );
    } else {
      this.attendeesSubject.next(this.attendees);
    }
  }

  public updateAttendees(){
    if(this.attendees){
      let updateAttendees = []; //data to be updated in MM database
      let missedAttendees = []; //array of GM account numbers

      this.attendees.forEach((person) =>{
        if(person.touched){
          updateAttendees.push({
            //To push other data, simply add it to this object.
            //No need to update the backend
            is_meeting_contact: person.is_meeting_contact,
            is_confirmed: person.is_confirmed,
            mark_email_change: person.mark_email_change,
            aid: person.aid
          });
          person.touched = false;
        }
        if(!person.is_confirmed) missedAttendees.push(person.gm_account_num);
      });

      if(updateAttendees.length > 0){
        this.backendService.postRequest(this.userService.token, '/updateDb', {
          table: 'attendees',
          data: updateAttendees
        }).subscribe(
          () => {},
          (err) => {
            console.log(err);
            alert("Warning, changes may not have been saved!\nPlease check your connection!\n" 
          + "Error:" +  err);
          },
          ()=>{} //end of completed updateDB
        );
      }
    }
  } //end of update attendees

    public getLinkedAttendees(mid: number) {
        this.backendService.getRequest<LinkedAttendee[]>(this.userService.token,
            `/getAttendees?mid=${mid}&stf=0`).subscribe(
                (res: LinkedAttendee[]) => {
                    if (res) {
                        res.forEach(person => {
                            person.is_confirmed = +person.is_confirmed;
                            person.is_meeting_contact = +person.is_meeting_contact;
                            person.aid = +person.aid;
                            person.service_team_flyer = +person.service_team_flyer;
                            person.sent_pre_email = +person.sent_pre_email;
                            person.sent_post_email = +person.sent_post_email;
                        });
                        this.linkedAttendees = res;
                    } else {
                        this.linkedAttendees.length = 0;
                    }
                }, (err) => {
                    console.log(err);
                }
            );
    }
}
