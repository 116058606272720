import { Injectable, OnDestroy } from '@angular/core';
import { BackendConnectionService } from './backend.connection.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class UtilitiesService implements OnDestroy {

    public primaryColor = '#59a1b7';
    public secondaryColor = '#e07972';
    public thirdColor = '#cfa16a';
    public colors = [
        "#59A1B7", //primary blue
        "#E07972", //secondary salmon
        "#9C8D94", //purple gray
        "#59b76f", //brighter green
        "#6459b7", //dark blue-purple
        "#cfa16a", //third color
        "#572f5c", //very dark purple
        "#2d515c", //dark primary blue
        "#775b39"  //dark brown
    ];
    public colorIndex = 0;
    public toolTips: any[] = [];
    public timeZone: string = "(" + new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1] + ")";

    private subs: any[] = [];

    constructor(
        //private backendService: BackendConnectionService,
        //private userService: UserService
    ) {
        //cannot call user services here anymore because of public endpoints.
        // //Get tool tips from database
        // this.backendService.getRequest(this.userService.token, 
        //   `/fetchDb?select=*&table=tooltips`).subscribe(
        //     (res: any[]) => {
        //       this.toolTips = res;
        //     },
        //     (err) => {
        //       console.log(err);
        //     }
        //   );
        // this.subs.push(this.userService.userServiceSubjet.subscribe(
        //   () => {
        //     this.backendService.getRequest(this.userService.token,
        //       `/logVersion?user=${this.userService.user.id}&version=2.0.0`)
        //       .subscribe(
        //         (res) => {console.log(res)},
        //         (err) => {console.log(err)}
        //       );
        //   }
        // ));

    }

    get color() {
        let color = this.colors[this.colorIndex];
        this.colorIndex++;
        if (this.colorIndex == this.colors.length) this.colorIndex = 0;
        return color;
    }

    getWeekOfMonth (date: Date){
        var firstWeekday = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
        var offsetDate = date.getDate() + firstWeekday - 1;
        return Math.floor(offsetDate / 7);
    }

    //convert goldMine date to a usable Date() type
    convertDate(date: string): Date {
        let split = date.split('-');
        let day = split[2].split(' ');
        return new Date(+split[0], +split[1] - 1, +day[0]);
    }

    //convert goldMine date to a usable Date() type (UTC)
    convertDateUtc(date: string, time: string = "00:00"): Date {
        let split = date.split('-');
        let day = split[2].split(' ');
        let timeSplit = time ? time.split(':') : ["00", "00"];
        return new Date(Date.UTC(+split[0], +split[1] - 1, +day[0], +timeSplit[0], +timeSplit[1]));
    }

    //generate a random sring to help with accordion ID's
    randString(length: number): string {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    randNumber(min: number, max: number) {
        return Math.floor(Math.random() * (+max - +min)) + +min;
    }

    //10:00AM
    formatTime(inputTime: string): string {
        if (!inputTime) return '';
        let timeSplit = inputTime.split(':');
        let hour = Number(timeSplit[0]);
        let minutes = timeSplit[1];

        return String(hour > 12 ? hour - 12 : hour) + ":" + String(minutes) +
            (hour >= 12 ? "PM" : "AM");
    }

    //February 24
    dateToStringMonthDay(date: Date): string {
        if (!date) return "#ERR#";
        return date.toLocaleString('en-us', { month: 'long', day: 'numeric' });
    }

    //11/05/1986
    dateToCommonFormat(date: Date): string {
        return date.toLocaleDateString('en-us',
            { month: '2-digit', day: '2-digit', year: 'numeric' });
    }

    //Thursday, May 23rd
    dateToStringDay(date: Date, addMonth: boolean = false): string {
        if (!date) return "";
        let month = "";
        if (addMonth) {
            month = date.toLocaleString('en-us', { month: 'long' }).replace(/[^ -~]/g, '');
        }
        let x = date.getDate();
        let suffix = "";
        switch (true) {
            case (x == 1): case (x == 21): case (x == 31):
                suffix = (addMonth ? ", " + month + " " : " , The ") + x + "st";
                break;
            case (x == 2): case (x == 22):
                suffix = (addMonth ? ", " + month + " " : " , The ") + x + "nd";
                break;
            case (x == 3): case (x == 23):
                suffix = (addMonth ? ", " + month + " " : " , The ") + x + "rd";
                break;
            case (x > 3 || x < 21): case (x > 23 || x < 31):
                suffix = (addMonth ? ", " + month + " " : " , The ") + x + "th";
                break;
            default:
                suffix = "";
                break;
        }
        return date.toLocaleDateString('en-us', { weekday: 'long' }).replace(/[^ -~]/g, '') + suffix;
    }

    /**
     * Format a JS date into sql dateTime
     * @param date 
     */
    sqlDateTimeISO(date: Date) {
        return date.toISOString().slice(0, 19).replace('T', ' ')
    }

    /**
     * Format a JS date into sql dateTime
     * @param date 
     */
    sqlDateTime(date: Date) {
        return date.toString().slice(0, 19).replace('T', ' ')
    }

    getWeek(input: Date) {
        var date = new Date(input.getTime());
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        var week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
            - 3 + (week1.getDay() + 6) % 7) / 7);
    }


    trimObjectStrings(inputObj: any) {
        Object.keys(inputObj).forEach((element) => {
            //trim key
            element.trim();
            if (typeof (inputObj[element]) === 'string') {
                //trim values
                inputObj[element] = inputObj[element].trim();
            }
        });
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }

}
