import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { GoldmineService } from '@app/core/services/goldmine.service';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { WeekDay, LinkedMeeting } from '@app/core/models/goldmine.model';
import { Subscription } from 'rxjs';
import { SwiperDirective, SwiperComponent } from 'ngx-swiper-wrapper';
import { PhoneSelectComponent } from './phone-select/phone-select.component';
import { MatDialog } from '@angular/material';
import { GMAppUpdateComponent } from './gm-update-dialogs/gm-app-update.component';
import { UserService } from '@app/core/services/user.service';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';
import { Router } from '@angular/router';
import { LoaderDialogComponent } from '../loader-dialog/loader-dialog.component';
import { FinalSelectorDialogComponent } from '../selector-dialog/final-selector-dialog.component';
import { SelectorDialogComponent } from '../selector-dialog/selector-dialog.component';
import { MeetingLinkComponentComponent } from '../selector-dialog/meeting-link-component/meeting-link-component.component';

@Component({
  selector: 'app-meetingdetails',
  templateUrl: './meetingdetails.component.html',
  styleUrls: ['./meetingdetails.component.scss']
})
export class MeetingDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('meetingsCarousel') meetingsCarousel: SwiperComponent;

  public weekDay: WeekDay = new WeekDay();
  private subscription: Subscription;

  public innerHeight: number;

  public index: number;

  public callContact: string;

  public testLoader: any;
  public linkedMeeting: LinkedMeeting;

  private picsDir = '/assets/pics/';
  public icons = {
    attendees: this.picsDir + 'attendees200.png',
    notes: this.picsDir + 'meetingnotes200-2.png',
    followup: this.picsDir + 'followup200.png',
    email: this.picsDir + 'email200.png',
    call: this.picsDir + 'call200.png',
    time: this.picsDir + 'updateinfo.png',
    complete: this.picsDir + 'updatemeeting.png',
    link: this.picsDir + 'link.png',
  };


  constructor(
    private userService: UserService,
    private backendService: BackendConnectionService,
    public goldMine: GoldmineService,
    public utilities: UtilitiesService,
    private router: Router,
    private changeDetect: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if(this.goldMine.meetingsLoaded){
      this._init();
    } else {
      this.subscription = this.goldMine.meetingsSubject.subscribe(
        () => {
          this._init();
          this.meetingsCarousel.directiveRef.update(); //re-init the carousel
        }
      );
    }
  }

  private _init(){
    console.log("init meeting Details");
    this.weekDay = this.goldMine.weekday;
    this.index = this.goldMine.meetingIndex;
    this.linkedMeeting = this.goldMine.meeting.linkedMeeting;
  }
  
  
  public setCarouselIndex(index: number){
    this.index = index;
  }
  
  public onIndexChange(index: number){
    this.goldMine.updateMeeting(index); //Helps Splash Component
    this.index = index;
    localStorage.setItem('meetingIndex', String(index));
  }

  public openPhoneDialog(contacts){
    const dialogRef = this.dialog.open(PhoneSelectComponent, {
      data: contacts
    });
  }

  public openUpdateDialog(){
    const dialogRef = this.dialog.open(GMAppUpdateComponent, {
      data: {
        type: 'meeting',
        attendee: null
      }
    });
  }

  public openMeetingLinkDialog(){
      const dialogRef = this.dialog.open(MeetingLinkComponentComponent);
      dialogRef.afterClosed().subscribe(
          (dialogRes: any) => {
              if(dialogRes.linkID){
                //A meeting will need to be linked to this meeting
                //First insert the the linkage into the meetings_relation table
                this.backendService.getRequest(this.userService.token, 
                    `/insertMeetingLink?parent=${this.goldMine.meeting.mid}&child=${dialogRes.linkID}`).subscribe(
                        (res: any) => {
                            //If response is good we need to link the meeting to this one.
                            if(!res.status) return;
                            this.goldMine.meeting.linkedMeeting = new LinkedMeeting();
                            this.goldMine.meeting.linkedMeeting.full_name = dialogRes.fullName;
                            this.goldMine.meeting.linkedMeeting.child_mid = dialogRes.linkID;
                            this.goldMine.meeting.linkedMeeting.ts_reference = dialogRes.tsReference;
                            this.linkedMeeting = this.goldMine.meeting.linkedMeeting;
                        }, (err) => {console.log(err)}
                    )
              }
          }
      )
  }

  public finalizeMeeting(){
      let context = this.goldMine.meeting.isTradeShow ? "Appointment" : "Show";
    let confirmDialogRef = this.dialog.open(FinalSelectorDialogComponent, {
      data: {
          message: `<p>Are you sure you want to finalize this ${context}?</p>`,
          isTradeShow: this.goldMine.meeting.isTradeShow
      }
    });
    confirmDialogRef.afterClosed().subscribe(
      (sub: any) => {
        if(sub.res){
          let loaderDialogRef = this.dialog.open(LoaderDialogComponent, { panelClass: 'smaller-container-width'});
          this.backendService.postRequest(this.userService.token,
            '/performActionsFromMemory', { mid: this.goldMine.meeting.mid }).subscribe(
              (res) => {
                console.log(res);
                if(res.noAttendees){
                  loaderDialogRef.close();
                  let confirmDialog = this.dialog.open(SelectorDialogComponent,{
                    data: {
                      selectorType: 'confirm',
                      message:
                    `<p>One of your followup actions does not have attendees! Add some attendees to it, or remove it, and try again.</p>`
                    }
                  });
                  return;
                } else {
                  this.backendService.postRequest(this.userService.token,
                    '/finalizeAppointment', { 
                      mid: this.goldMine.meeting.mid,
                      companyName: this.goldMine.meeting.company,
                      meetDate: this.goldMine.weekday.convertDate.toISOString(),
                      location: this.goldMine.event.location,
                      reference: this.goldMine.meeting.ref,
                      result: sub.selections.result,
                      code: sub.selections.code
                    }).subscribe(
                      (res) => {
                        console.log(res);
                        if(res.fault){
                          loaderDialogRef.close();
                          this.dialog.open(SelectorDialogComponent,{
                            data: {
                              selectorType: 'confirm',
                              message: res.message
                            }
                          });
                          return;
                        } else {
                          this.router.navigate(['']);
                          loaderDialogRef.close();
                          setTimeout(() => {
                            this.goldMine.deleteMeeting();
                          }, 500);
                        }
                      },
                      (err) => {
                        console.log(err);
                        loaderDialogRef.close();
                        alert("Warning server error while trying to finalize!\nPlease check your connection!\n" 
                        + "Error:" +  err);
                      }
                    )
                }
              },
              (err) => {
                console.log(err);
                loaderDialogRef.close();
                alert("Warning followup actions may not have completed!\nPlease check your connection!\n" 
                + "Error:" +  err);
              }
            );
        }
      }
    );
    
  }

  saveZoom(showReminder = false){
      let data = this.goldMine.meeting.zoomInfo;
      let mid = this.goldMine.meeting.mid;
      if(!data.register_link && !data.id && !data.pass && !data.join_link && !data.record_link){
          return;
      }
      let insert = false;
      if(!this.goldMine.meeting.zoomInfo.mid){
        insert = true;
      }
      this.backendService.postRequest(this.userService.token, '/saveZoom',{ mid: mid, data: data, insert: insert})
      .subscribe( (res) => {
          if(!res.status){
              alert(res.message);
          } else {
              if(insert)
                this.goldMine.meeting.zoomInfo.mid = mid;
              if(showReminder){
              this.dialog.open(SelectorDialogComponent,{
                    data: {
                        selectorType: 'confirm',
                        message:
                    `<p>Zoom info saved</p>`
                    }
                });   
              }
          }
      })
  }

  togglePlatform(){
      setTimeout(()=>{
        this.backendService.postRequest(this.userService.token, 
        '/updateDb', {
            table: 'meetings',
            data: [{
            their_platform: this.goldMine.meeting.their_platform? '1' : '0',
            mid: this.goldMine.meeting.mid
            }]
        }).subscribe(
            (res) => console.log(res),
            (err) => {
            console.log(err);
            alert("Warning, changes may not have been saved!\nPlease check your connection!\n" 
            + "Error:" +  err);
            }
      )}, 300);
  }

  ngOnDestroy(){
    if(this.subscription) this.subscription.unsubscribe();
    this.saveZoom();
  }
}