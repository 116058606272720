import { Component, OnInit, Inject, OnDestroy, ViewChild, NgZone, ElementRef, AfterContentInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Attendee, LinkedAttendee } from '@app/core/models/attendees.model';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';
import { UserService } from '@app/core/services/user.service';
import { Meeting } from '@app/core/models/goldmine.model';
import { GoldmineService } from '@app/core/services/goldmine.service';
import { SelectorDialogComponent } from '@app/modules/selector-dialog/selector-dialog.component';
import { UtilitiesService } from '@app/core/services/utilities.service';

export interface Results {
    ACCOUNTNO: string,
    CONTACT: string,
    PHONE1: string,
    PHONE2: string
}
export interface DialogData {
    meeting: Meeting,
    attendees: Attendee[];
    linkedAttendees: LinkedAttendee[];
}

@Component({
    selector: 'app-add-contact',
    templateUrl: './add-contact.component.html',
    styleUrls: ['./add-contact.component.scss']
})

export class AddContactComponent implements OnInit, OnDestroy {

    @ViewChild('nameInput') nameInput: ElementRef;
    @ViewChild('emailInput') emailInput: ElementRef;
    @ViewChild('firstNameRawInput') firstNameRawInput: ElementRef;

    public telephone: string;
    public dearValue: string;
    public lastnameValue: string;
    public emailValue: string;
    public searchResults: Results[] = null;
    public attendee: Attendee;
    public attendees: Attendee[];
    public meeting: Meeting;

    constructor(
        private userService: UserService,
        private goldMine: GoldmineService,
        private backendService: BackendConnectionService,
        private dialog: MatDialog,
        private utilities: UtilitiesService,
        public dialogRef: MatDialogRef<AddContactComponent>,
        private ngZone: NgZone,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
    ) { }

    ngOnInit() {
        this.attendees = this.dialogData.attendees;
        this.meeting = this.dialogData.meeting;
        //get local storage items
        this.setFormValues(
            localStorage.getItem('dear'),
            localStorage.getItem('lastname'),
            localStorage.getItem('email'),
            localStorage.getItem('phone1')
        );
    }

    ngAfterContentInit() {
        setTimeout(() => {
            //Autofocus on #nameInput
            this.nameInput.nativeElement.focus();
        }, 200);
    }

    reFocus(event: any) {
        setTimeout(() => {
            if (event == 0) {
                this.nameInput.nativeElement.focus();
            }
            if (event == 1) {
                this.firstNameRawInput.nativeElement.focus();
            }
        }, 200);
    }

    onKey(event: any) {
        //Check which input the event is coming from and clear out the other input
        if (event.target.attributes.id.nodeValue === 'name-input') {
            this.emailInput.nativeElement.value = '';
        } else {
            this.nameInput.nativeElement.value = '';
        }

        let name = this.nameInput.nativeElement.value;
        let email = this.emailInput.nativeElement.value;

        if (name.length > 2 || email.length) {
            let searchData = `?name=${name}&email=${email}`;
            //Booth shows need to search the entire database.
            if (!this.meeting.isMultiCompany) {
                searchData += "&macola=" + this.meeting.macola
                    + "&company=" + this.meeting.company.replace("&", "%26");
            }

            this.backendService.getRequest<any>(this.userService.token, `/searchContacts${searchData}`)
                .subscribe((res) => {
                    this.searchResults = res;
                });
        } else {
            this.searchResults = null;
        }
    }

    updatePhone(input) {
        this.telephone = input.replace(/[^0-9\.]+/g, '');
        if (this.telephone.length >= 10) {
            console.log(this.telephone.length);
            if(this.telephone.length > 10){
                this.telephone = this.telephone.slice(0, 10);
            }
            this.telephone = this.telephone.slice(0, 3)
                + "-" + this.telephone.slice(3, 6)
                + "-" + this.telephone.slice(6);
            console.log(this.telephone);
        }
    }

    addGMContact(contact) {
        let contactData = {
            mid: Number(this.meeting.mid),
            gm_account_num: contact.ACCOUNTNO,
            title: contact.TITLE ? contact.TITLE : "",
            email: contact.EMAIL ? contact.EMAIL : "",
            gm_recid: contact.recid ? contact.recid : "",
            full_name: contact.CONTACT,
            phone1: contact.PHONE1 ? contact.PHONE1 : "",
            phone2: contact.PHONE2 ? contact.PHONE2 : "",
            state: contact.STATE ? contact.STATE : ""
        };
        this.utilities.trimObjectStrings(contactData);
        let exists = this.attendeeExists(contactData.full_name);
        if(exists.att !== undefined){
            let confirmDialog = this.dialog.open(SelectorDialogComponent, {
                data : {
                    selectorType: 'yesNo',
                    message : `
                    <div class="info_container">
                        <h5>Attendee already exists on ${exists.message} meeting!</h5>
                        <p style="margin: 0"><strong>Existing Attendee:</strong></p>
                        <div>${exists.att.full_name}</div>
                        <div>${exists.att.email || 'no email info'}</div>
                        <div>${exists.att.phone1 || 'no phone info'}</div>
                        <div style="height: 16px;"></div>
                        <p style="margin: 0"><strong>New Attendee:</strong></p>
                        <div>${contactData.full_name}</div>
                        <div>${contactData.email || 'no email info'}</div>
                        <div>${contactData.phone1 || 'no phone info'}</div>
                        <div style="height: 16px;"></div>
                        <p style="margin: 0"><strong>Add this attendee anyway?</strong></p>
                    </div>
                    `
                }
            });
            confirmDialog.afterClosed().subscribe((res: boolean) => {
                if(res){
                    this.dialogRef.close(contactData);
                }
            });
        } else {
            this.dialogRef.close(contactData);
        }
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    createContact(data: NgForm): void {
        let relevantData = {
            dear: data.value.dear.trim(),
            lastname: data.value.lastname.trim(),
            email: data.value.email.trim(),
            phone1: data.value.phone1,
            key1: this.meeting.key1 ? this.meeting.key1.trim() : "",
            key3: this.meeting.key3 ? this.meeting.key3.trim() : "",
            key4: this.meeting.key4 ? this.meeting.key4.trim() : "",
            key5: this.meeting.macola ? this.meeting.macola.trim() : "",
            ucss: this.meeting.ucss ? this.meeting.ucss.trim() : "",
            ucss2: this.meeting.ucss2 ? this.meeting.ucss2.trim() : "",
            address1: this.meeting.address1 ? this.meeting.address1.trim() : "",
            address2: this.meeting.address2 ? this.meeting.address2.trim() : "",
            city: this.meeting.city ? this.meeting.city.trim() : "",
            state: this.meeting.state ? this.meeting.state.trim() : "",
            zip: this.meeting.zip ? this.meeting.zip.trim() : "",
            company: this.meeting.company ? this.meeting.company.trim() : "",
            mid: this.meeting.mid, //needed to insert new contact into MM database
            meetDate: this.goldMine.weekday.convertDate.toISOString() //needed to add dist Mtg
        }
        //Check if attendee is on imediate list already
        let fullName = relevantData.dear + " " + relevantData.lastname;
        if (this.attendees.findIndex(p => p.full_name == fullName) != -1) {
            alert("Contact already exists on your attendee list!");
            return;
        }

        this.goldMine.createContact(relevantData);
        this.ngZone.run(() => {
            this.goldMine.createContactSubject.subscribe(
                (res) => { //server will responde with the aid
                    if (res.insertData && res.accountno) {
                        this.setFormValues('', '', '', '');
                        let contactData = {
                            aid: Number(res.insertData.insertId),
                            mid: this.meeting.mid,
                            service_team_flyer: Number(res.insertData.stf),
                            gm_account_num: res.accountno,
                            email: relevantData.email,
                            full_name: fullName,
                            phone1: relevantData.phone1,
                            phone2: "",
                            state: this.meeting.state
                        }
                        this.dialogRef.close(contactData);
                    }
                }
            );
        });
    }

    private attendeeExists(fullName: string) {
        let found, message;
        if (this.dialogData.linkedAttendees.length > 0) {
            found = this.dialogData.linkedAttendees.find(
                ({full_name}) => full_name == fullName
            );
            if(found !== undefined) message = 'linked';
        }
        if(found === undefined){
            found = this.attendees.find(
                ({full_name}) => full_name == fullName
            );
            if(found !== undefined) message = 'your';
        }
        return {
            att: found,
            message: message
        };
    }

    private setFormValues(dear: string, lastname: string, email: string, phone: string) {
        this.dearValue = dear != 'null' ? dear : "";
        this.lastnameValue = lastname != 'null' ? lastname : "";
        this.emailValue = email != 'null' ? email : "";
        this.telephone = phone != 'null' ? phone : "";
    }

    ngOnDestroy() {
        localStorage.setItem('dear', this.dearValue);
        localStorage.setItem('lastname', this.lastnameValue);
        localStorage.setItem('email', this.emailValue);
        localStorage.setItem('phone1', this.telephone);
    }

}
