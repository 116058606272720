import { Component, Inject, ChangeDetectorRef, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect, MatInput } from '@angular/material';
import { NgForm } from '@angular/forms';
import { EmailCan, EmailAction } from '@app/core/models/email.model';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { PostEmailService } from '@app/core/services/post-email.service';
import { Attendee } from '@app/core/models/attendees.model';
import { UserService } from '@app/core/services/user.service';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent implements OnInit {

  @ViewChild('preEmailTextArea') preEmailTextArea: ElementRef; 
  @ViewChild('postEmailTextArea') postEmailTextArea: ElementRef;
  @ViewChild('emailSelectForm') emailForm: NgForm;

  public preEmailIndex: number;
  public postEmailIndex: number;

  public postEmailSubject: string;
  public preEmailSubject: string;

  public postEmailBody: string;
  public preEmailBody: string;

  public postSnippetIndex: number;

  public persons: EmailAction[];
  public tabSelect: number;
  public isTradeShow: boolean;
  public actvcode: string;

  public linkList: any[];

  constructor(
    private userService: UserService,
    private backendService: BackendConnectionService,
    private utilities: UtilitiesService,
    public dialogRef: MatDialogRef<EmailDialogComponent>,
    private pEService: PostEmailService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dialogData: { 
      persons: EmailAction[], 
      preEmailCannedMsgs: EmailCan[],
      postEmailCannedMsgs: EmailCan[],
      defaultPreEmailEmid: number,
      defaultPostEmailEmid: number,
      tabSelect: number,
      isTradeShow: boolean,
      actvcode: string
    }
  ) 
  {
    this.postSnippetIndex = -1;
    this.tabSelect = this.dialogData.tabSelect;
    this.persons = this.dialogData.persons;
    
    this.isTradeShow = this.dialogData.isTradeShow;
    this.actvcode = this.dialogData.actvcode;

    //find pre email for person
      this.preEmailIndex = this.dialogData.preEmailCannedMsgs.findIndex(
          (msg) => {
              if (this.persons.length == 1)
                  return msg.emid == this.persons[0].preEmail.emid;
              else return msg.emid == this.dialogData.defaultPreEmailEmid;
          }
      );
    let foundPre = this.dialogData.preEmailCannedMsgs[this.preEmailIndex];
    this.preEmailSubject = foundPre.subject;
    this.preEmailBody = foundPre.body;

    //find post email for person
      this.postEmailIndex = this.dialogData.postEmailCannedMsgs.findIndex(
          (msg) => {
              if (this.persons.length == 1)
                  return msg.emid == this.persons[0].postEmail.emid;
              else return msg.emid == this.dialogData.defaultPostEmailEmid;
          }
      );
    let foundPost = this.dialogData.postEmailCannedMsgs[this.postEmailIndex];
    this.postEmailSubject = foundPost.subject;
    this.postEmailBody = foundPost.body;

    //Get a list of links from the database
    this.backendService.getRequest(this.userService.token,
      `/fetchDb?table=external_links`)
      .subscribe(
        (res: any[]) => {
          this.linkList = res;
        }
      );
  }

  ngOnInit(){
    this.cd.detectChanges();
  }
  
  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  preEmailChange(event: any){
    let email = this.dialogData.preEmailCannedMsgs[event.value];
    this.preEmailSubject = email.subject;
    this.preEmailBody = email.body;
  }

  postEmailChange(event: any){
    let email = this.dialogData.postEmailCannedMsgs[event.value];
    this.postEmailSubject = email.subject;
    this.postEmailBody = email.body;
  }

  //http://blog.sodhanalibrary.com/2016/10/get-cursor-position-from-text-area.html#.XLD_KTBKhaR
  addSnippetToPost(event: MatSelect): void{
    if(event.value == -1) return;
    let index = event.value;
    let el = this.postEmailTextArea.nativeElement;
    const selEnd = el.selectionEnd;
    let insertString = "\n\n<a href='" + this.linkList[index].link_url + "'>" + this.linkList[index].link_formal_name + "</a>";

    if (el.selectionStart || el.selectionStart == '0') {
       let caretPos = el.selectionStart;
       this.postEmailBody = el.value.substr(0, caretPos) + insertString + el.value.substr(caretPos);
        setTimeout(()=>{
          el.focus();
          el.selectionEnd = selEnd + insertString.length;
        }, 200); 
    }
  }

  saveSelections(selectForm: NgForm){
    let customEmails: {
      pre: EmailCan,
      post: EmailCan
    } = { pre: null, post: null };

    let preEmailFromIndex = this.dialogData.preEmailCannedMsgs[this.preEmailIndex];
    let postEmailFromIndex = this.dialogData.postEmailCannedMsgs[this.postEmailIndex];

    let persons = this.persons;
    let setPersonPre = (email: any, custom: boolean = false) => {
        persons.forEach( person => {
            if(person.preEmail.emid !== email.emid){
                if(!person.onHold){
                    person.preEmail.emid = email.emid;
                    person.preEmail.name = email.name;
                }
            }
        });
    }
    let setPersonPost = (email: any, custom: boolean = false) => {
        persons.forEach( person => {
            if(person.postEmail.emid !== email.emid){
                if(!person.onHold){
                    person.postEmail.emid = email.emid;
                    person.postEmail.name = email.name;
                }
            }
        });
    }

    //Check if user changed the text in pre email
        if(this.preEmailBody.length !== preEmailFromIndex.body.length){
            let newEmail = new EmailCan({
            emid: this.utilities.randNumber(9999, 999999),
            area: 'pre-email',
            name: '',
            subject: this.preEmailSubject,
            body: this.preEmailBody
            });
            this.pEService.allEmails[newEmail.emid] = newEmail;
            let pushIndex = this.pEService.cusPreEmails.push(newEmail);
            this.pEService.cusPreEmails[pushIndex - 1].name = 'Custom ' + String(pushIndex);
            newEmail.name = 'Custom ' + String(pushIndex);
            customEmails.pre = newEmail;
            this.dialogData.preEmailCannedMsgs.push(newEmail);
            setPersonPre(newEmail, true);
        } else {
            setPersonPre(preEmailFromIndex);
        }

    //Check if user changed the text in post email
        if(this.postEmailBody.length !== postEmailFromIndex.body.length){
            //let randNumber = ;
            let newEmail = new EmailCan({
            emid: this.utilities.randNumber(9999, 999999),
            area: 'post-email',
            name: '',
            subject: this.postEmailSubject,
            body: this.postEmailBody
            });
            this.pEService.allEmails[newEmail.emid] = newEmail;
            let pushIndex = this.pEService.cusPostEmails.push(newEmail);
            this.pEService.cusPostEmails[pushIndex - 1].name = 'Custom ' + String(pushIndex);
            newEmail.name = 'Custom ' + String(pushIndex);
            customEmails.post = newEmail;
            this.dialogData.postEmailCannedMsgs.push(newEmail);
            setPersonPost(newEmail, true);
        } else {
            setPersonPost(postEmailFromIndex);
        }

    this.dialogRef.close();
  }

  onNoClick(){
    this.dialogRef.close();
  }
}
